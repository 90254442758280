/*
========================================== Close message cookies =========================================

● This IIFE function checks if the localstorage has the variable "closeMessage" with a value of true.
● If the value is true, the cookies message will no longer be displayed on the screen.
● In order for the message to be displayed again, it is necessary to delete the cookies from the browser.
● The variable "closeMessage" in localstorage will take the value of true when the event is executed by pressing the x of the alert.
===============================================================================================================*/


// ==========================================================================================================

const accordion = document.querySelectorAll("#accordion-content");

accordion.forEach((item, index) => {
    let button = item.querySelector(".show-more-btn");
    let description = item.querySelector(".accordion-description");
    let lineHeight = parseInt(window.getComputedStyle(description).lineHeight);
    let totalHeight = description.scrollHeight;

    // Calculate the numer of lines
    let numberOfLines = Math.ceil(totalHeight / lineHeight);

    if (numberOfLines <= 1) {
        button.style.display = "none";
    }

    if (button) {
        button.addEventListener("click", () => {
            item.classList.toggle("open");

            if (item.classList.contains("open")) {
                description.style.height = `${description.scrollHeight}px`;
                button.innerHTML = "Ver menos";
            } else {
                description.style.height = "20px";
                button.innerHTML = "Ver más";
            }
            removeOpen(index);
        });
    }
});

function removeOpen(index1) {
    accordion.forEach((item2, index2) => {
        let button = item2.querySelector(".show-more-btn");
        if (index1 != index2) {
            item2.classList.remove("open");
            button.innerHTML = "Ver más";
            let desc = item2.querySelector(".accordion-description");
            desc.style.height = "20px";
        }
    });
}

function showAlertItemCart(e) {

    const alertContainer = document.querySelector(".add-item-pdp-alert");
    const body = document.querySelector("#body-scroll");
    const colors = document.querySelectorAll(".color-attribute");
    const selectSize = document.querySelector("#talla-1");
    const sizeList = document.querySelectorAll(".talla-attribute")

    var messages = {};
    var message;
    //var select = selectSize.value;
    console.log(colors);
    console.log(sizeList);

    let colorError = true, sizeError = true;
    colors.forEach((item, index) => {
        const colorSwatch = item.querySelector("span");
        if (colorSwatch.classList.contains("selected")){
            colorError = false;
        }
    });

    sizeList.forEach((item, index) => {
        const colorSwatch = item.querySelector("span");
        if (colorSwatch.classList.contains("selected")){
            sizeError = false;
        }
    });

    if (sizeError){
        return;
    }

    if (colorError){
        messages.error = "Tienes que seleccionar un color";
    }
    /*if (sizeError) {
        messages.error = "Tienes que seleccionar una tall";
    }*/
    /*if (select === "talla" && messages.error) {
        message = "Tienes que seleccionar un color y una talla.";
    } else if ((select = "talla" && messages.success)) {
        message = "Tienes que seleccionar una talla";
    } else if (!select && !messages.success) {
        message = "Tienes que seleccionar un color";
    }*/

}

function showAlertItemBuy(e) {
    var button = e.children[0].childNodes[5].disabled;
    const alertContainer = document.querySelector(".add-item-pdp-alert");
    const body = document.querySelector("#body-scroll");
    const colors = document.querySelectorAll("#colorsSelecteable");
    const selectSize = document.querySelector("#talla-1");
    var messages = {};
    var message;
    var select = selectSize.value;

    colors.forEach((item, index) => {
        const colorSwatch = item.querySelector("span");
        colorSwatch.classList.contains("selected")
            ? (messages.success = "seleccionado")
            : (messages.error = "color");
    });

    if (select === "talla" && messages.error) {
        message = "Tienes que seleccionar un color y una talla.";
    } else if ((select = "talla" && messages.success)) {
        message = "Tienes que seleccionar una talla";
    } else if (!select && !messages.success) {
        message = "Tienes que seleccionar un color";
    }

}

// function nextStep(e) {
//   const buttonShipping = e.children[0].children[1].disabled;
//   const labelURL = e.children[0].children[0].dataset.href;

//   buttonShipping === true ? window.location.replace(labelURL) : null;
// }

function homeRedirectLogin() {
    url = document.getElementById("redirectlogin").dataset.href;
    sessionStorage.setItem("homeAlertRegister", true);

    window.location.replace(url);
}

function checkoutRedirectLogin() {
    sessionStorage.setItem("checkoutAlertRegister", true);
    document.getElementById("checkout-alert").classList.add("d-none");
}

function closeAlertPromotion() {
    const checkout = document.getElementById("checkout-alert");
    const home = document.getElementById("homepage-alert");

    if (checkout) {
        checkout.classList.add("d-none");
        sessionStorage.setItem("checkoutAlertRegister", true);
    }
    if (home) {
        home.classList.add("d-none");
        sessionStorage.setItem("homeAlertRegister", true);
    }

}

if (
    sessionStorage.getItem("homeAlertRegister") === null &&
    document.getElementById("homepage-alert")
)
    document.getElementById("homepage-alert").classList.remove("d-none");
if (
    sessionStorage.getItem("checkoutAlertRegister") === null &&
    document.getElementById("checkout-alert")
)
    document.getElementById("checkout-alert").classList.remove("d-none");


const productId = document.querySelector(".productID .productID__id");

if (productId) {
    window.addEventListener("change", () => {
        setTimeout(() => {
            const productId = document.querySelector(
                ".productID .productID__id"
            );
            const SKU = document.querySelector(".productSku .product-id");

            if (productId.innerText != SKU.innerText) {
                $(".productSku").removeClass("d-none");
            }
        }, 500);
    });
}

// ==================================================== BUTTONS CLOSE COOKIES======================================================

var showCookie = false;

const popupCheckout = document.getElementById("modal-check");

const btnCloseMessageCookies = document.querySelector(".checkout-alert-register-card-close");

(function () {

    const storage = localStorage.getItem("closeMessage");

    if (storage) {

        popupCheckout?.classList.add("d-none");

    } else {

        popupCheckout?.classList.remove("d-none");

    }

})();

btnCloseMessageCookies?.addEventListener("click", function () {

    localStorage.setItem("closeMessage", true);

    popupCheckout.classList.add("d-none");

});

var showCookie2 = false;
const popupCheckout2 = document.getElementById("modal-check");

const btnCloseMessageCookiesx = document.querySelector(".checkout-alert-register-card-closex");

(function () {

    const storage = localStorage.getItem("closeMessage");

    if (storage) {

        popupCheckout2?.classList.add("d-none");

    } else {

        popupCheckout2?.classList.remove("d-none");

    }

})();

btnCloseMessageCookiesx?.addEventListener("click", function () {

    localStorage.setItem("closeMessage", true);

    popupCheckout2.classList.add("d-none");

});

module.exports = function() {
    $('.add-to-cart-btn').click(function (e) {
    e.preventDefault();
        showAlertItemCart(e);
    })

}
