
function sendToTikTok(event) {
    if (event.ecommerce) {
        ttq.track(event.event, event);
    }
}

window.dataLayer = window.dataLayer || [];
window.dataLayer.push = function(event) {
    Array.prototype.push.apply(this, arguments);
    sendToTikTok(event);
};

window.dataLayer.forEach(function(event) {
    sendToTikTok(event);
});
