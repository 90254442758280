window.jQuery = window.$ = require('jquery');
var processInclude = require('base/js/util');

$(document).ready(function () {
    processInclude(require('base/js/components/menu'));
    processInclude(require('base/js/components/cookie'));
    processInclude(require('custom/js/components/consentTracking'));
    processInclude(require('custom/js/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('base/js/components/search'));
    processInclude(require('base/js/components/clientSideValidation'));
    processInclude(require('base/js/components/countrySelector'));
    processInclude(require('base/js/components/toolTip'));
    // processInclude(require('./minified/carousel/carousel'));
    processInclude(require('./experience/components/accordion/accordion'));

    processInclude(require('./login'));
    processInclude(require('./processGTMEvents'));
});
require('owl.carousel/src/js/owl.carousel');
require('base/js/thirdParty/bootstrap');
require('base/js/components/spinner');
